import React from 'react';
import PropTypes from 'prop-types';
import LabelInfo from './LabelInfo';
import { formatDate } from '../constants/fr';

const FrOtherInfo = ({ data }) => {
  return (
    <div className="subpage fr-page">
      <div className="watermark">SECRET</div>
      <ol style={{ listStyleType: 'none' }}>
        <li>
          <ul style={{ listStyleType: 'none' }}>
            <li className="mb-10px">
              HAVE YOU AVAILED OF ANY GOVERNMENT PROGRAMS FOR RETURNEES BEFORE?
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {['YES', 'NO'].map((item) => (
                  <li key={item} style={{ width: '25%' }}>
                    <input
                      checked={item === data.availed_gov_programs_before}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </li>
        <li>
          <LabelInfo label="IF YES, WHAT BENEFITS DID YOU RECIEVE FROM SAID PROGRAM?">
            {data.benefits_received_from_gov_program}
          </LabelInfo>
        </li>
      </ol>
      <ol type="I" start={5}>
        <li className="mb-10px">
          <div className="mb-10px">INTERVIEW DETAILS</div>
          <ul style={{ listStyleType: 'none' }}>
            <li className="mb-10px">
              <LabelInfo label="DATE WHEN INTERVIEW WAS CONDUCTED?">
                {formatDate(data.interview_date)}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="PLACE WHERE INTERVIEW WAS CONDUCTED?">
                {data.interview_place}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="NAME AND UNIT OF PERSONNEL WHO CONDUCTED THE INTERVIEW?">
                {data.interviewer_name}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="NAME OF ENCODER">{data.encoder_name}</LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="DATE OF ENCODING">
                {formatDate(data.encoding_date)}
              </LabelInfo>
            </li>
          </ul>
        </li>
        <li className="mb-10px">
          <div className="mb-10px">
            COMMENTS/OBSERVATIONS OF THE INTERVIEWER
          </div>
          <div style={{ whiteSpace: 'nowrap' }} className="mb-10px">
            {data.comments}
          </div>
        </li>
        <li className="mb-10px">
          <div className="mb-10px">RECOMMENDATIONS IF ANY</div>
          <div style={{ whiteSpace: 'nowrap' }} className="mb-10px">
            {data.recommendations ?? ''}
          </div>
        </li>
      </ol>
      <div className="pic-wrapper">
        <div className="full-body-wrapper">
          <div className="full-body">
            {data.whole_body_photo ? (
              <img src={data.whole_body_photo} alt="whole-body" />
            ) : (
              <div style={{ textAlign: 'center' }}>
                <div>FULL-BODY PICTURE OF THE FR</div>
                <div>(FRONT VIEW)</div>
              </div>
            )}
          </div>
        </div>
        <div className="finger-prints-wrapper">
          <div className="finger-print-wrapper">
            <div className="finger-print">
              {data.left_index_finger && <img src={data.left_index_finger} alt="whole-body" />}
            </div>
            <div className="">
              <div>FR's LEFT</div>
              <div>THUMBS MARK</div>
            </div>
          </div>
          <div className="finger-print-wrapper">
            <div className="finger-print">
              {data.right_index_finger && <img src={data.right_index_finger} alt="whole-body" />}
            </div>
            <div className="">
              <div>FR's RIGHT</div>
              <div>THUMBS MARK</div>
            </div>
          </div>
        </div>
      </div>
      <div className="signature-wrapper">
        <div>
          <div className="sign"></div>
          <div>SIGNATURE OVER PRINTED NAME OF THE FR</div>
        </div>
      </div>
      <div className="no-of-sheets">
        <div>(NUMBER OF ATTACHED SHEETS - </div>
        <div className="counts"></div>
        <div>WITH NAME OF FR ON TOP)</div>
      </div>
    </div>
  );
};

FrOtherInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FrOtherInfo;
