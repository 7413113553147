import React, { useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { req } from 'react-reqq';
import useSelectorApi from 'helpers/useSelectorApi';
import FrPersonalInfo from '../components/FrPersonalInfo';
import FrFamilyProfile from '../components/FrFamilyProfile';
import FrHistory from '../components/FrHistory';
import FrHistory2 from '../components/FrHistory2';
import FrHistory3 from '../components/FrHistory3';
import FrOtherInfo from '../components/FrOtherInfo';

const useGetId = () => {
  const router = useRouteMatch();
  const id = useMemo(() => {
    return router.params?.id ?? '';
  }, [router.params]);
  return id;
};

const useProfile = (id) => {
  const personInfo = useSelectorApi('personInfo', {});
  useEffect(() => {
    req.get({
      key: 'personInfo',
      url: `/person/${id}`,
      transform: (res) => res.data,
    });
  }, [id]);
  return personInfo;
};

const useMembership = (id) => {
  const membership = useSelectorApi('membershipInfo', {});
  useEffect(() => {
    req.get({
      key: 'membershipInfo',
      url: `/person/${id}/membership`,
      transform: ({ data }) => data,
    });
  }, [id]);
  return membership;
};

const useEducationList = (id) => {
  const education = useSelectorApi('educationList', []);
  useEffect(() => {
    req.get({
      key: 'educationList',
      url: `/person/${id}/education`,
      transform: ({ data }) => data,
    });
  }, [id]);
  return education;
};

const useFamilyList = (id) => {
  const family = useSelectorApi('familyList', []);
  useEffect(() => {
    req.get({
      key: 'familyList',
      url: `/person/${id}/family`,
      transform: ({ data }) => data,
    });
  }, [id]);
  return family;
};

const useOtherInformation = (id) => {
  const otherInfo = useSelectorApi('otherInfo', {});
  useEffect(() => {
    req.get({
      key: 'otherInfo',
      url: `/person/${id}/other-information`,
      transform: ({ data }) => data,
    });
  }, [id]);
  return otherInfo;
};

// const useDocumentList = (id) => {
//   const documentList = useSelectorApi('documentList', []);
//   useEffect(() => {
//     req.get({
//       key: 'documentList',
//       url: `/person/${id}/document`,
//       transform: ({ data }) => data,
//     });
//   }, [id]);
//   return documentList;
// };

// const useRemarks = (id) => {
//   const remarksData = useSelectorApi('remarksData', {});
//   useEffect(() => {
//     req.get({
//       key: 'remarks',
//       url: `/person/${id}/remarks`,
//       transform: ({ data }) => data,
//     });
//   }, [id]);
//   return remarksData;
// };

// const useAssistanceList = (id) => {
//   const assistanceList = useSelectorApi('assistanceList', []);
//   useEffect(() => {
//     req.get({
//       key: 'assistanceList',
//       url: `/person/${id}/assistance`,
//       transform: ({ data }) => data,
//     });
//   }, [id]);
//   return assistanceList;
// };

// const useFirearmList = (id) => {
//   const firearmList = useSelectorApi('firearmList', []);
//   useEffect(() => {
//     req.get({
//       key: 'firearmList',
//       url: `/person/${id}/firearm`,
//       transform: ({ data }) => data,
//     });
//   }, [id]);
//   return firearmList;
// };

// const useDispositionList = (id) => {
//   const dispositionList = useSelectorApi('dispositionList', []);
//   useEffect(() => {
//     req.get({
//       key: 'dispositionList',
//       url: `/person/${id}/disposition`,
//       transform: ({ data }) => data,
//       params: {
//         paginate: 0,
//       },
//     });
//   }, [id]);
//   return dispositionList;
// };

const PrintFR = () => {
  const id = useGetId();
  const profile = useProfile(id);
  const membership = useMembership(id);
  const familyList = useFamilyList(id);
  const educationList = useEducationList(id);
  const otherInfo = useOtherInformation(id);
  // const documentList = useDocumentList(id);
  // const remarks = useRemarks(id);
  // const assistanceList = useAssistanceList(id);
  // const firearmList = useFirearmList(id);
  // const dispositionList = useDispositionList(id);

  useEffect(() => {
    const body = document.body;
    body.classList.add('former-rebel');
    return () => {
      body.classList.remove('former-rebel');
    };
  }, []);
  
  return (
    <>
      <div className="page">
        <FrPersonalInfo
          data={{
            ...profile,
            ...membership,
          }}
        />
      </div>
      <div className="page">
        <FrFamilyProfile
          profile={profile}
          educationList={educationList}
          familyList={familyList}
        />
      </div>
      <div className="page">
        <FrHistory
          data={{
            ...membership,
          }}
        />
      </div>
      <div className="page">
        <FrHistory2
          data={{
            ...profile,
            ...membership,
          }}
        />
      </div>
      <div className="page">
        <FrHistory3
          data={{
            ...membership,
            ...otherInfo,
          }}
        />
      </div>
      <div className="page">
        <FrOtherInfo
          data={{
            ...profile,
            ...otherInfo,
          }}
        />
      </div>
    </>
  );
};

export default PrintFR;
