import React from 'react';
import PropTypes from 'prop-types';
import LabelInfo from './LabelInfo';

const LabelInfoList = ({ formList, data, limit }) => {
  const getLabelInfoList = (form, index) => {
    const elements = (limit ? form.filter((item, i) => i <= limit) : form).map(
      (item, i) => {
        if (Array.isArray(item)) {
          return (
            <div key={`${index}-${i}`} className="label-info-wrapper">
              {getLabelInfoList(item, index + 1)}
            </div>
          );
        }
        return (
          <LabelInfo
            key={`${index}-${i}`}
            label={item.label}
            wrapperWidth={item.wrapperWidth}
          >
            {item.cellRenderer ? item?.cellRenderer() : data[item.key] ?? ''}
          </LabelInfo>
        );
      }
    );

    return elements;
  };

  return <>{getLabelInfoList(formList, 1)}</>;
};

LabelInfoList.propTypes = {
  formList: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.instanceOf(Object),
  limit: PropTypes.number,
};

LabelInfoList.defaultProps = {
  limit: 0,
  data: {},
};

export default LabelInfoList;
