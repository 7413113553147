import React from 'react';
import PropTypes from 'prop-types';
import LabelInfoList from './LabelInfoList';
import LabelInfo from './LabelInfo';
import {
  reasonsMotives,
  natureOfCases,
  govAssistances,
  parseJson,
  getTable,
  formatDate,
} from '../constants/fr';

const forms = [
  {
    label: '1',
    key: 'name 1',
  },
  {
    label: '2',
    key: 'name 2',
  },
  {
    label: '3',
    key: 'name 3',
  },
];

const getFormList = (str, key = 'name') => {
  try {
    const data = JSON.parse(str) ?? [];
    return forms.map((item, i) => ({
      ...item,
      cellRenderer: () => data?.[i]?.[key] ?? '',
    }));
  } catch (error) {
    return forms;
  }
};

const FrHistory3 = ({ data }) => {
  const returnFoldsLaw = parseJson(data.reasons_to_return, {});
  return (
    <div className="subpage fr-page">
      <div className="watermark">SECRET</div>
      <ol style={{ listStyleType: 'none' }}>
        <li>
          <ol start={15} style={{ listStyleType: 'upper-alpha' }}>
            <li>
              <div className="mb-10px">
                PERSONALITIES WHO FACILITATED THE RETURN OF THE FR TO THE FOLDS
                OF THE LAW
              </div>
              <LabelInfoList
                formList={getFormList(data.personalities_facilitated_return)}
              />
            </li>
            <li>
              <div className="mb-10px">
                CIRCUMSTANCES OF FR’s DECISION TO RETURN TO THE FOLDS OF THE LAW
              </div>
              <LabelInfoList
                formList={getFormList(data.circumstances_of_decision_to_return)}
              />
            </li>
            <li>
              <div className="mb-10px">
                REASON(S)/MOTIVATION FOR THE FR TO RETURN TO THE FOLDS OF THE
                LAW
              </div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {reasonsMotives.map((item) => (
                  <li
                    key={item.label}
                    style={{ width: item.width }}
                    className="mb-10px"
                  >
                    <input
                      checked={item.label === returnFoldsLaw?.reasons_to_return}
                      type="checkbox"
                      onChange={() => {}}
                    />{' '}
                    {item.label}
                  </li>
                ))}
              </ul>
              <LabelInfo label="REMARKS">{returnFoldsLaw?.remarks}</LabelInfo>
            </li>
            <li>
              <div className="mb-10px">FIREARMS</div>
              <table className="table-bordered w-100 mb-10px">
                <tbody>
                  <tr>
                    <td width="33%">MAKE</td>
                    <td width="33%">CALIBER</td>
                    <td width="33%">SERIAL NUMBER</td>
                  </tr>
                  {getTable(data.firearms, [
                    'make',
                    'caliber',
                    'serial_number',
                  ])}
                </tbody>
              </table>
            </li>
          </ol>
        </li>
      </ol>
      <ol start={3} type="I">
        <li>
          <div style={{ margin: '10px 0' }}>LEGAL IMPEDIMENTS</div>
          <ul style={{ listStyleType: 'none' }}>
            <li className="mb-10px">
              <LabelInfo label="IS THERE AN EXISTING WARRANT/S OF ARREST FOR THE FR?">
                {data.has_existing_warrant_of_arrest}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">NATURE OF THE CASE</div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {natureOfCases.map((item) => (
                  <li key={item} style={{ width: '25%' }}>
                    <input
                      checked={item === data.nature_of_case}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
            <li className="mb-10px">
              <LabelInfo label="ISSUING COURT">{data.issuing_court}</LabelInfo>
            </li>
          </ul>
        </li>
        <li>
          <div className="mb-10px">
            GOVERNMENT PROGRAMS FOR REBELS WHO RETURNED TO THE FOLDS OF THE LAW
          </div>
          <ul style={{ listStyleType: 'none' }}>
            <li>
              <div className="mb-10px">
                IS YOUR FIRST TIME TO RETURN TO THE FOLDS OF THE LAW?
              </div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {['YES', 'NO'].map((item) => (
                  <li key={item} style={{ width: '25%' }}>
                    <input
                      checked={item === data.first_time_to_return}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
            <li>
              <div className="mb-10px">
                IF YES, WHAT ASSISTANCE DO YOU REQUEST FROM THE GOVERNMENT?
              </div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {govAssistances.map((item) => (
                  <li key={item} style={{ width: '33%' }}>
                    <input
                      checked={item === data.assistance_requested_from_gov}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
            <li className="mb-10px">
              <LabelInfo label="IF NO, WHEN DO YOU FIRST RETURN TO THE FOLDS OF THE LAW?">
                {formatDate(data.first_return_date)}
              </LabelInfo>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

FrHistory3.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FrHistory3;
