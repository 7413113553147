/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import { req } from 'react-reqq';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';

const ReportByCategory = () => {
  const data = useSelector((state) => state.api.searchList || [], shallowEqual);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);
    req.get({
      key: 'searchList',
      url: '/search/person',
      params: {
        ...formattedParams,
        type: 'B',
      },
    });

    return () => {
      req.set('searchList', []);
    };
  }, []);

  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - General Report (By category, sub-category)</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td width="20%" className="">
                Category
              </td>
              <td width="20%" className="">
                Sub Category
              </td>
              <td width="50%" className="">
                Region
              </td>
              <td width="10%" className="text-center">
                Total
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((category) => {
              let totalPerCategory = 0;

              (category.sub_categories || []).forEach((subCategory) => {
                (subCategory.regions || []).forEach((region) => {
                  totalPerCategory += region.persons.length;
                });
              });

              return (
                <Fragment key={category.category_code}>
                  <tr>
                    <td>{category.category_name}</td>
                    <td></td>
                    <td></td>
                    <td className="text-center">{totalPerCategory}</td>
                  </tr>
                  {(category.sub_categories || []).map((subCategory) => (
                    <Fragment key={subCategory.sub_category_code}>
                      <tr>
                        <td></td>
                        <td>{subCategory.sub_category_name}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      {(subCategory.regions || []).map((region) => (
                        <tr key={region.region_code}>
                          <td></td>
                          <td></td>
                          <td>
                            {region.region_name || ''} ({region.persons.length})
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(ReportByCategory);
