import React from 'react';
import moment from 'moment';
import stringConcat from 'helpers/stringConcat';

const dateFormat = 'YYYY-MM-DD';

export const parseJson = (str, defaultValue) => {
  try {
    return JSON.parse(str);
  } catch (error) {
    return defaultValue;
  }
};

export const dEl = <div style={{ visibility: 'hidden' }}>x</div>;

const getTableColumns = (data, keys, i) => (
  <tr key={i}>
    {keys.map((item) => (
      <td key={`${item}-${i}`}>{data[item] || dEl}</td>
    ))}
  </tr>
);

export const getTable = (str, keys, count = 4) => {
  const data = str ? JSON.parse(str) : [];
  const field = [];
  for (let i = 0; i < count; i++) {
    const item = data[i] ?? {};
    field.push(getTableColumns(item, keys, i));
  }
  return field;
};

export const formatDate = (date) => {
  const dateMoment = moment(date, dateFormat);
  if (dateMoment.isValid()) {
    return dateMoment.format('MMMM DD, YYYY');
  }
  return '';
};

export const personalInfoLabel = [
  {
    label: 'LAST NAME',
    key: 'last_name',
  },
  {
    label: 'FIRST NAME',
    key: 'first_name',
  },
  {
    label: 'MIDDLE NAME',
    key: 'middle_name',
  },
  {
    label: 'PREFIX',
    key: 'suffix',
  },
  {
    label: 'MAIDEN NAME, IF MARRIED',
    key: 'maiden_name',
  },
  {
    label: 'PSEUDONYM / ALIAS',
    key: 'alias',
  },
  {
    label: 'TRIBAL GROUP',
    key: 'tribal_group',
  },
  [
    {
      label: 'SEX',
      key: 'gender',
    },
    {
      label: 'MARITAL STATUS',
      key: 'marital_status',
    },
  ],
  {
    label: 'DATE OF BIRTH',
    key: 'birth_date',
  },
  {
    label: 'PLACE OF BIRTH',
    key: 'place_of_birth',
  },
  [
    {
      label: 'AGE',
      key: 'age',
    },
    {
      label: 'WEIGTH',
      key: 'weight',
    },
    {
      label: 'HEIGHT',
      key: 'height',
    },
  ],
  [
    {
      label: 'COMPLEXION',
      key: 'complexion',
    },
    {
      label: 'COLOR OF HAIR',
      key: 'hair',
    },
    {
      label: 'COLOR OF EYES',
      key: 'eyes',
    },
  ],
  [
    {
      label: 'RELIGION',
      key: 'religion',
    },
    {
      label: 'NATIONALITY',
      key: 'citizenship',
    },
  ],
  {
    label: 'TELEPHONE NUMBER/S',
    key: 'mobile_number',
  },
  {
    label: 'IDENTIFYING MARKS / CHARACTERISTICS',
    key: 'distinguishing_mark',
  },
  {
    label: 'PRESENT ADDRESS',
    key: 'present_address',
  },
  [
    {
      label: "DRIVER'S LICENSE NR",
      key: 'driver_license_number',
    },
    {
      label: 'ISSUED AT',
      key: 'driver_license_issued_place',
    },
    {
      label: 'ON',
      key: 'driver_license_issued_date',
    },
  ],
  [
    {
      label: 'RES CERT NR',
      key: 'res_certificate_number',
    },
    {
      label: 'DATE & PLACE OF ISSUE',
      key: 'res_certificate_issued_date',
    },
  ],
  {
    label: 'OTHER CARDS PRESENTED',
    key: 'nationality',
  },
  {
    label: 'OCCUPATION',
    key: 'occupation',
  },
  {
    label: 'HEALTH PROBLEM, IF ANY',
    key: 'health_problems',
  },
];

export const getChildrenList = (data) => {
  const field = [];

  for (let i = 0; i < 4; i++) {
    const item = data[i] ?? {};
    field.push([
      {
        label: 'NAME',
        key: 'name',
        wrapperWidth: '200%',
        cellRenderer: () =>
          stringConcat([
            item?.first_name ?? '',
            item?.middle_name ?? '',
            item?.last_name ?? '',
            item?.suffix ?? '',
          ]),
      },
      {
        label: 'AGE',
        key: 'age',
        cellRenderer: () => item.age ?? '',
      },
    ]);
  }

  return field;
};

export const homeOccupationInfo = [
  {
    label: 'HOME ADDRESS',
    key: 'address',
  },
  {
    label: 'OCCUPATION',
    key: 'occupation',
  },
];

export const getPersonInfo = (label = 'NAME', key = 'name') => [
  [
    {
      label,
      key,
      wrapperWidth: '200%',
    },
    {
      label: 'AGE',
      key: 'age',
    },
  ],
];

export const educs = [
  'PRIMARY',
  'SECONDARY',
  'TERTIARY',
  'VOCATIONAL',
  'MASTERAL',
  // 'DOCTORATE',
];

export const reasonsForJoining = [
  { width: '33%', label: 'VENGEANCE' },
  { width: '33%', label: 'COERCED TO JOIN' },
  { width: '33%', label: 'EVADE ARREST' },
  { width: '33%', label: 'DISILLUSIONMENT' },
  { width: '33%', label: 'PROMISED OF MONEY' },
  { width: '33%', label: 'SECURITY' },
  { width: '100%', label: 'STRONG BELIEF IN THE COMMUNIST IDEOLOGY' },
  { width: '100%', label: 'INFLUENCE OF FAMILY PEER INFLUENCE' },
  { width: '100%', label: 'SOCIAL MEDIA INFLUENCE' },
  { width: '100%', label: 'OTHERS' },
];

export const statusOfPartyMembership = [
  'FULL-PLEDGE MEMBER',
  'CANDIDATE MEMBER',
  'OTHER',
];

export const govEntity = ['AFP', 'PNP', 'NICA', 'NBI', 'OTHERS', 'N/A'];

export const reasonsMotives = [
  { label: 'HARDSHIP', width: '33%' },
  { label: 'HEALTH', width: '33%' },
  { label: 'DISGRUNTLEMENT', width: '33%' },
  { label: 'ENLIGHTENMENT', width: '33%' },
  { label: 'PATY IN-FIGHTING', width: '33%' },
  { label: 'FAMILY', width: '33%' },
  { label: 'OLD AGE', width: '33%' },
  { label: 'UNDER DISCIPLINARY ACTION OF THE PARTY', width: '66%' },
  { label: 'SECURITY', width: '33%' },
];

export const natureOfCases = [
  'REBELLION',
  'THEFT',
  'KIDNAPPING',
  'MURDER',
  'PHYSICAL INJURIES',
  'EXTORTION',
  'HOMICIDE',
  'ARSON',
  'ROBBERY',
  'RAPE',
];

export const govAssistances = [
  'OTHERS',
  'LIVELIHOOD PROGRAM',
  'HOUSING',
  'FINANCIAL',
];
