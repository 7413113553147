const stringConcat = (strArr) => {
  let str = '';
  strArr.forEach((item) => {
    if (item) {
      str = `${str} ${item}`;
    }
  });
  return str.trim();
};

export default stringConcat;
