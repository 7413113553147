import React from 'react';
import PropTypes from 'prop-types';
import LabelInfo from './LabelInfo';
import stringConcat from 'helpers/stringConcat';
import { govEntity, getTable, formatDate } from '../constants/fr';

const FrHistory2 = ({ data }) => {
  return (
    <div className="subpage fr-page">
      <div className="watermark">SECRET</div>
      <ol style={{ listStyleType: 'none' }}>
        <li>
          <ol start={9} style={{ listStyleType: 'upper-alpha' }}>
            <li>
              <div className="mb-10px">PARTY COURSES/TRAINING UNDERTAKEN</div>
              <table className="table-bordered w-100 mb-10px">
                <tbody>
                  <tr>
                    <td width="33%">INCLUSIVE DATES</td>
                    <td width="33%">TRAINING</td>
                    <td width="33%">INSTRUCTOR</td>
                  </tr>
                  {getTable(data.trainings_undertaken, [
                    'inclusive_dates',
                    'training',
                    'instructor',
                  ])}
                </tbody>
              </table>
            </li>
            <li>
              <div className="mb-10px">INVOLVEMENT IN TACTICAL OPERATIONS</div>
              <table className="table-bordered w-100 mb-10px">
                <tbody>
                  <tr>
                    <td width="33%">DATE/PLACE OF TACTICAL OPERATION</td>
                    <td width="33%">UNIT ASSIGNED</td>
                    <td width="33%">AFP/PNP UNIT ENCOUNTERED/ATTACKED</td>
                  </tr>
                  {getTable(data.tactical_operations_involvement, [
                    'date_place',
                    'unit_assigned',
                    'encountered_unit',
                  ])}
                </tbody>
              </table>
            </li>
            <li>
              <div className="mb-10px">
                LEADERS/MEMBERS KNOWN TO THE FR OR ARE IN CONTACT WITH
              </div>
              <table className="table-bordered w-100 mb-10px">
                <tbody>
                  <tr>
                    <td width="33%">NAME</td>
                    <td width="33%">POSITION</td>
                    <td width="33%">AREA OF OPERATIONS</td>
                  </tr>
                  {getTable(data.members_in_contact, [
                    'name',
                    'position',
                    'area',
                  ])}
                </tbody>
              </table>
            </li>
            <li>
              <div className="mb-10px">
                IDENTITIES OF COLLECTIVE/S IN THE ORGANIZATION
              </div>
              <table className="table-bordered w-100 mb-10px">
                <tbody>
                  <tr>
                    <td width="33%">NAME</td>
                    <td width="33%">POSITION</td>
                    <td width="33%">AREA OF OPERATIONS</td>
                  </tr>
                  {getTable(data.identities_of_collectives, [
                    'name',
                    'position',
                    'area',
                  ])}
                </tbody>
              </table>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">
                DATE AND PLACE OF FR’S RETURN TO THE FOLDS OF THE LAW
              </div>
              <div className="mb-10px">
                <LabelInfo label="DATE">
                  {formatDate(data.dates_and_places_of_return_to)}
                </LabelInfo>
              </div>
              <LabelInfo label="ADDRESS">
                {stringConcat([
                  data?.address ? `${data?.address},` : '',
                  data?.city ? `${data?.city},` : '',
                  data?.province ? `${data?.province},` : '',
                  data?.region ?? '',
                ])}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">
                GOVERNMENT ENTITY THAT FACILITATED THE RETURN OF THE FR TO THE
                FOLDS OF THE LAW
              </div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {govEntity.map((item) => (
                  <li key={item} className="mb-10px">
                    <input
                      checked={
                        data.gov_entities_entity_facilitated_return === item
                      }
                      type="checkbox"
                      onChange={() => {}}
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

FrHistory2.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FrHistory2;
