/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import { req } from 'react-reqq';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';

const ReportByRegionProvince = () => {
  const data = useSelector((state) => state.api.searchList || [], shallowEqual);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);

    req.get({
      key: 'searchList',
      url: '/search/person',
      params: {
        ...params,
        ...formattedParams,
        type: 'A',
      },
    });
  }, []);

  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - General Report (By region, province)</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td className="">REGION (No. of FRs)</td>
              <td className="">PROVINCE</td>
              <td className="">NAME</td>
              <td className="">Surrendered Place / Date</td>
              <td width="10%" className="text-center">Total</td>
            </tr>
            {/* <tr>
              <td width="20%" className="">
                REGION (No. of FRs)
              </td>
              <td width="15%" className="">
                PROVINCE
              </td>
              <td width="20%" className="">
                NAME
              </td>
              <td width="20%" className="">
                Surrendered Place / Date
              </td>
              <td width="10%" className="text-center">
                Total
              </td>
            </tr> */}
          </thead>
          <tbody>
            {data.map((region) => {
              let totalPerRegion = 0;

              (region.provinces || []).forEach((province) => {
                totalPerRegion += (province.persons || []).length;
              });

              return (
                <Fragment key={region.region_code}>
                  <tr>
                    <td>{region.region_name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="text-center">{totalPerRegion}</td>
                  </tr>
                  {(region.provinces || []).map((province) => (
                    <Fragment key={province.province_code}>
                      <tr>
                        <td></td>
                        <td>
                          {province.province_name} (
                          {(province.persons || []).length})
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                      {(province.persons || []).map((person, personIndex) => (
                        <tr key={personIndex}>
                          <td></td>
                          <td></td>
                          <td
                            style={{ wordWrap: 'breakWord' }}
                            // width="30% !important"
                            width="250px"
                          >
                            <div style={{ wordWrap: 'breakWord' }}>
                              {person.name || ''}{' '}
                              {person.alias ? `(${person.alias})` : ''}
                            </div>
                            <div>{person.category}</div>
                            <div>{person.code}</div>
                          </td>
                          <td>
                            {person.place_surrendered || (
                              <span style={{ fontSize: 14 }}>n/a</span>
                            )}{' '}
                            -{' '}
                            {person.date_surrendered || (
                              <span style={{ fontSize: 14 }}>n/a</span>
                            )}
                          </td>
                          <td></td>
                        </tr>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(ReportByRegionProvince);
