import React, { useEffect } from 'react';
import moment from 'moment';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';
import useSelectorApi from 'helpers/useSelectorApi';
import { useLocation } from 'react-router-dom';
import { req } from 'react-reqq';
import stringConcat from 'helpers/stringConcat';

const getName = (data) =>
  stringConcat([
    data?.first_name ?? '',
    data?.middle_name ?? '',
    data?.last_name ?? '',
    data?.suffix ?? '',
    data?.alias ? `(${data.alias})` : '',
  ]);

const useGetData = () => {
  const data = useSelectorApi('searchList', []);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);
    req.get({
      key: 'searchList',
      url: '/search/person',
      params: {
        ...formattedParams,
        ...params,
        type: 'E',
      },
    });
  }, [location.search]);

  return data;
};

const ReportByRefnoName = () => {
  const data = useGetData();
  console.log(data, 'argeel');
  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - General Report (By reference number, name)</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td width="15%" className="">
                Ref No.
              </td>
              <td width="35%" className="">
                Full Name
              </td>
              <td width="10%" className="">
                Age
              </td>
              <td width="10%" className="text-center">
                Year
              </td>
              <td width="15%" className="text-center">
                Group
              </td>
              <td width="15%" className="text-center">
                Location
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((item) => (
              <tr>
                <td>{item.reference_number}</td>
                <td>{getName(item)}</td>
                <td>{item.age}</td>
                <td>{item.year}</td>
                <td>{item.group}</td>
                <td>{item.location}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ReportByRefnoName;
