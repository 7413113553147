import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { configureApi } from 'react-reqq';

import App from './App';
import * as serviceWorker from './serviceWorker';
import * as apiConfiguration from 'helpers/api';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/css/font.css';
import 'assets/scss/material-dashboard-pro-react.scss';
import 'assets/scss/style.scss';

const store = configureApi({
  endpoint: process.env.REACT_APP_ENDPOINT,
  onError: apiConfiguration.onError,
  requestHeaders: apiConfiguration.requestHeaders,
});

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
