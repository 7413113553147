import React from 'react';
import PropTypes from 'prop-types';
import defaultAvatar from 'assets/img/profile-default.jpg';
import { personalInfoLabel } from '../constants/fr';
import LabelInfoList from './LabelInfoList';

const FrPersonalInfo = ({ data }) => (
  <div className="subpage fr-page">
    <div className="watermark">SECRET</div>
    <div className="footer">SECRET</div>
    <div className="title-wrapper">
      <div className="title">
        ENHANCED FORMEL REBEL (FR) INFORMATION SHEET (FRIS)
      </div>
      <div className="pic-wrapper">
        <div className="pic-inner">
          <img src={data.photo ?? defaultAvatar} alt="pic" />
        </div>
      </div>
    </div>
    <table className="fr-no">
      <tbody>
        <tr>
          <td>FRIS No.:</td>
          <td className="value">{data.code}</td>
        </tr>
        <tr>
          <td />
          <td>(DAY/MONTH/YR/REGION/PROVINCE/FR INITIALS)</td>
        </tr>
      </tbody>
    </table>
    <div style={{ marginBottom: 10 }}>
      INSTRUCTION: THIS QUESTIONNAIRE IS TO BE ACCOMPLISHED BY THE INTERVIEWEE
      IS IMPORTANT TO COMPLETE THIS QUESTIONNAIRE. PLEASE ANSWER OBJECTIVELY.
      WRITE NA FORMS THAT IS NOT APPLICABLE TO THE FR.
    </div>
    <ol type="I">
      <li>
        <div style={{ marginBottom: 10 }}>PERSONAL PROFILE</div>
        <ol style={{ listStyleType: 'upper-alpha' }}>
          <li>
            <div style={{ marginBottom: 10 }}>PERSONAL DATA</div>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li>
                <LabelInfoList data={data} formList={personalInfoLabel} />
              </li>
            </ul>
          </li>
        </ol>
      </li>
    </ol>
  </div>
);

FrPersonalInfo.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FrPersonalInfo;
