import React, { Suspense, useEffect } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { req } from 'react-reqq';

import Print from './modules/Print/container/Print';

const Routes = React.lazy(() => import('./Routes'));

function App() {
  useEffect(() => {
    const token = localStorage.getItem('token');
    req.set('isLogin', !!token);
  }, []);

  return (
    <Suspense fallback={<div className="loading-screen" />}>
      <Switch>
        <Route path="/print" component={Print} />
        <Routes />
      </Switch>
      <ToastContainer />
    </Suspense>
  );
}

export default withRouter(App);
