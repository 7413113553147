import React, { useEffect } from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';

import ReportByRegionProvince from './ReportByRegionProvince';
import ReportByCategory from './ReportByCategory';
import ReportDistribution from './ReportDistribution';
import ReportByAgencyUser from './ReportByAgencyUser';
import DataEntry from './DataEntry';
import PrintFR from './PrintFR';
import ReportByRefnoName from './ReportByRefnoName';

const Print = () => {
  useEffect(() => {
    const body = document.body;
    body.classList.add('print');
    return () => {
      body.classList.remove('print');
    };
  }, []);

  return (
    <Switch>
      <Route path="/print/region-province">
        <ReportByRegionProvince />
      </Route>
      <Route path="/print/category">
        <ReportByCategory />
      </Route>
      <Route path="/print/distribution">
        <ReportDistribution />
      </Route>
      <Route path="/print/agency-user">
        <ReportByAgencyUser />
      </Route>
      <Route path="/print/data-entry">
        <DataEntry />
      </Route>
      <Route path="/print/refno-name">
        <ReportByRefnoName />
      </Route>
      <Route path="/print/fr/:id">
        <PrintFR />
      </Route>
      <Redirect from="/" to="/print/region-province" />
    </Switch>
  );
};

export default withRouter(Print);
