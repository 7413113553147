/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import { req } from 'react-reqq';
import useSelectorApi from 'helpers/useSelectorApi';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';

const ReportDistribution = () => {
  const data = useSelectorApi('searchList', []);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);
    req.get({
      key: 'searchList',
      url: '/search/person',
      params: {
        ...formattedParams,
        type: 'D',
      },
    });

    return () => {
      req.set('searchList', []);
    };
  }, []);

  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - FR Distribution by Region, Agency, Type of Assistance</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td width="15%" className="text-center">
                Date
              </td>
              <td width="25%" className="text-center">
                Agency
              </td>
              <td width="20%" className="text-center">
                FR Name
              </td>
              <td width="20%" className="text-center">
                Office/Division
              </td>
              <td width="20%" className="text-center">
                User Log in Name
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <td className="text-center">{item.date}</td>
                <td className="text-center">{item.agency}</td>
                <td className="text-center">{item.name}</td>
                <td className="text-center">
                  {item.department || ''}
                  {item.department && item.division ? '/' : ''}
                  {item.division || ''}
                </td>
                <td className="text-center">{item.creator}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(ReportDistribution);
