import React from 'react';
import PropTypes from 'prop-types';
import LabelInfoList from './LabelInfoList';
import LabelInfo from './LabelInfo';
import stringConcat from 'helpers/stringConcat';
import {
  getChildrenList,
  homeOccupationInfo,
  getPersonInfo,
  educs,
} from '../constants/fr';

const getName = (data) =>
  stringConcat([
    data?.first_name ?? '',
    data?.middle_name ?? '',
    data?.last_name ?? '',
    data?.suffix ?? '',
  ]);

const getAddress = (data) =>
  stringConcat([
    data?.address ? `${data?.address},` : '',
    data?.city ? `${data?.city},` : '',
    data?.province ? `${data?.province},` : '',
    data?.region ?? '',
  ]);

const getSibling = (sibling) => {
  const field = [];

  for (let i = 0; i < 3; i++) {
    const item = sibling[i] ?? {};

    field.push(
      <li key={i} style={{ marginBottom: 10 }}>
        <LabelInfoList
          data={{
            name: getName(item),
            age: item.age ?? '',
            occupation: item.occupation,
            address: getAddress(item),
          }}
          formList={[...getPersonInfo(), ...homeOccupationInfo]}
        />
      </li>
    );
  }

  return field;
};

const educDefault = <div style={{ visibility: 'hidden' }}>x</div>;

const getEduc = (education) => {
  const field = [];

  if (education.length > 0) {
    for (let i = 0; i < 4; i++) {
      const item = education[i] ?? {};
      field.push(
        <tr key={i}>
          <td>{item.school || educDefault}</td>
          <td>
            {item.year_from && item.year_to
              ? `${item.year_from}-${item.year_to}`
              : educDefault}
          </td>
          <td>{item.degree || educDefault}</td>
        </tr>
      );
    }
    return field;
  }
  return [
    <tr key="no-data">
      <td className="text-center" colSpan={3}>
        No Data
      </td>
    </tr>,
  ];
};

const FrFamilyProfile = ({ familyList, profile, educationList }) => {
  const spouse = familyList?.find((item) => item.relation === 'SPOUSE') ?? {};
  const child = familyList?.filter((item) => item.relation === 'CHILD') ?? [];
  const father = familyList?.find((item) => item.relation === 'FATHER') ?? {};
  const mother = familyList?.find((item) => item.relation === 'MOTHER') ?? {};
  const sibling =
    familyList?.filter((item) => item.relation === 'SIBLING') ?? [];
  const education = profile?.highest_educational_attainment || '';
  return (
    <div className="subpage fr-page">
      <div className="watermark">SECRET</div>
      <ul style={{ listStyleType: 'none' }}>
        <li>
          <ol start={2} style={{ listStyleType: 'upper-alpha' }}>
            <li>
              <div style={{ marginBottom: 10 }}>FAMILY PROFILE</div>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                <li>
                  <LabelInfo label="IF MARRIED, NAME OF SPOUSE">
                    {getName(spouse)}
                  </LabelInfo>
                  <LabelInfo label="ADDRESS">{getAddress(spouse)}</LabelInfo>
                </li>
                <li>CHILDREN</li>
                <li>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <LabelInfoList
                        data={{}}
                        formList={getChildrenList(child)}
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <LabelInfoList
                    data={{
                      name: getName(father),
                      age: father.age,
                    }}
                    formList={getPersonInfo('NAME OF FATHER')}
                  />
                </li>
                <li>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <LabelInfoList
                        data={{
                          address: getAddress(father),
                          occupation: father.occupation,
                        }}
                        formList={homeOccupationInfo}
                      />
                    </li>
                  </ul>
                </li>
                <li>
                  <LabelInfoList
                    data={{
                      name: getName(mother),
                      age: mother.age,
                    }}
                    formList={getPersonInfo('NAME OF MOTHER')}
                  />
                </li>
                <li>
                  <ul style={{ listStyleType: 'none' }}>
                    <li>
                      <LabelInfoList
                        data={{
                          address: getAddress(mother),
                          occupation: mother.occupation,
                        }}
                        formList={homeOccupationInfo}
                      />
                    </li>
                  </ul>
                </li>
                <li>SIBLINGS</li>
                <li>
                  <ul style={{ listStyleType: 'none' }}>
                    {getSibling(sibling)}
                  </ul>
                </li>
              </ul>
            </li>
            <li>
              <div style={{ marginBottom: 10 }}>EDUCATIONAL ATTAINMENT</div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {educs.map((item) => (
                  <li key={item} style={{ width: '25%' }}>
                    <input
                      checked={item === education}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
              <ul
                style={{
                  listStyleType: 'none',
                  padding: 0,
                }}
              >
                <table className="table-bordered">
                  <tbody>
                    <tr>
                      <td width="60%">NAME OF SCHOOL / ADDRESS</td>
                      <td width="20%">YEARS OF ATTENDANCE</td>
                      <td width="20%">DEGREE EARNED</td>
                    </tr>
                    {getEduc(educationList)}
                  </tbody>
                </table>
              </ul>
            </li>
          </ol>
        </li>
      </ul>
    </div>
  );
};

FrFamilyProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  educationList: PropTypes.arrayOf(Object).isRequired,
  familyList: PropTypes.arrayOf(Object).isRequired,
};

export default FrFamilyProfile;
