/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import { req } from 'react-reqq';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';

const ReportDistribution = () => {
  const data = useSelector((state) => state.api.searchList || [], shallowEqual);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);
    req.get({
      key: 'searchList',
      url: '/search/person',
      params: {
        ...formattedParams,
        type: 'C',
      },
    });

    return () => {
      req.set('searchList', []);
    };
  }, []);

  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - FR Distribution by Region, Agency, Type of Assistance</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td width="20%" className="">
                Agency
              </td>
              <td width="20%" className="">
                Type of Assistance
              </td>
              <td width="30%" className="">
                Region
              </td>
              <td width="30%" className="">
                Province
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((agency) => {
              return (
                <Fragment key={agency.agency_code}>
                  <tr>
                    <td>{agency.agency_name}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  {(agency.assistances || []).map((assistance) => (
                    <Fragment key={assistance.assistance_code}>
                      <tr>
                        <td></td>
                        <td>{assistance.assistance_name}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      {(assistance.regions || []).map((region) => (
                        <Fragment key={region.region_code}>
                          <tr>
                            <td></td>
                            <td></td>
                            <td>{region.region_name}</td>
                            <td></td>
                          </tr>
                          {(region.provinces || []).map((province) => (
                            <Fragment key={province.province_code}>
                              <tr>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>
                                  {province.province_name} ({province.persons.length})
                                </td>
                              </tr>
                            </Fragment>
                          ))}
                        </Fragment>
                      ))}
                    </Fragment>
                  ))}
                </Fragment>
              );
            })}
            {/* <tr>
              <td>DILG</td>
              <td>Immediate Assistance</td>
              <td>Region 1</td>
              <td>Zamboanga Del Norte</td>
            </tr> */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(ReportDistribution);
