/* eslint-disable */
import React, { useEffect, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import moment from 'moment';
import { req } from 'react-reqq';
import useSelectorApi from 'helpers/useSelectorApi';
import queryStrToObj from 'helpers/QueryStrToObj';
import formatPayload from 'helpers/formatPayload';

const DataEntry = () => {
  const data = useSelectorApi('printDataEntryList', []);
  const location = useLocation();

  useEffect(() => {
    const params = queryStrToObj(location.search);
    const formattedParams = formatPayload(params);
    req.get({
      key: 'printDataEntryList',
      url: '/data-entry',
      params: {
        ...formattedParams,
        paginate: 0,
      },
      transform: (res) => res.data,
    });

    return () => {
      req.set('printDataEntryList', []);
    };
  }, []);

  return (
    <div className="page">
      <div className="subpage print-general-report">
        <div className="date">
          <small>
            <i>{moment().format('MMMM DD, YYYY')}</i>
          </small>
        </div>
        <h4>E-FRIS - FR Distribution by Region, Agency, Type of Assistance</h4>
        <table className="print-table w-100">
          <thead>
            <tr>
              <td width="20%" className="text-center">
                Agency
              </td>
              <td width="20%" className="text-center">
                Department Unit
              </td>
              <td width="20%" className="text-center">
                FR's Name
              </td>
              <td width="20%" className="text-center">
                Encoder Email
              </td>
              <td width="20%" className="text-center">
                Date Encoded
              </td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, i) => (
              <tr key={i}>
                <td className="">{item.agency}</td>
                <td className="">{item.department}</td>
                <td className="">{item.name}</td>
                <td className="">{item.encoder_email || ''}</td>
                <td className="">{item.created_at}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRouter(DataEntry);
