import { toast } from 'react-toastify';
import _ from 'lodash';
import { req } from 'react-reqq';

const getError = (errors) => {
  const format = [];
  for (const key in errors) {
    if (errors.hasOwnProperty(key)) {
      format.push(errors[key]);
    }
  }
  return format;
};

const statusCodes = {
  422: (response) => {
    const errors = getError(response.errors);
    const message = _.get(errors, '0.0', '');
    toast.error(message);
  },
  400: (response) => {
    const message = _.get(response, 'message', '');
    toast.error(message);
  },
  403: () => {
    req.set('isLogin', false);
    localStorage.clear();
  },
};

export const onError = ({ response, status }) => {
  if (statusCodes[status]) {
    statusCodes[status](response);
  }
};

export const requestHeaders = () => {
  const token = localStorage.getItem('token') || '';
  return {
    Authorization: `Bearer ${token}`,
    // 'Content-Type': 'multipart/form-data',
  };
};
