import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LabelInfo from './LabelInfo';
import {
  formatDate,
  reasonsForJoining,
  statusOfPartyMembership,
  dEl,
} from '../constants/fr';

const getRecruiter = (i, name = '', position = '') => (
  <Fragment key={i}>
    <LabelInfo label="NAME">{name}</LabelInfo>
    <div className="mb-10px ml-10px">
      <LabelInfo label="POSITION IN THE ORGANIZATION">{position}</LabelInfo>
    </div>
  </Fragment>
);

const getRecruiters = (str) => {
  try {
    const recruiters = JSON.parse(str);
    const field = [];
    for (let i = 0; i < 3; i++) {
      const recruiter = recruiters[i] ?? {};
      field.push(getRecruiter(i, recruiter.name, recruiter.position));
    }
    return field;
  } catch (error) {
    return [getRecruiter(1), getRecruiter(2)];
  }
};

const getPos = (data = {}, i) => (
  <tr key={i}>
    <td>{data.organization || dEl}</td>
    <td>{data.position || dEl}</td>
    <td>{data.area_of_operations || dEl}</td>
    <td>{data.inclusive_dates || dEl}</td>
  </tr>
);

const getPositions = (str) => {
  try {
    const positions = JSON.parse(str);
    const field = [];
    for (let i = 0; i < 4; i++) {
      const pos = positions[i] ?? {};
      field.push(getPos(pos, i));
    }
    return field;
  } catch (error) {
    return (
      <tr>
        <td colSpan={4}>No Data</td>
      </tr>
    );
  }
};

const FrHistory = ({ data }) => {
  return (
    <div className="subpage fr-page">
      <div className="watermark">SECRET</div>
      <ol start={2} type="I">
        <li>
          <div style={{ marginBottom: 10 }}>
            HISTORY OF MEMBERSHIP IN THE ORGANIZATION
          </div>
          <ol style={{ listStyleType: 'upper-alpha' }}>
            <li className="mb-10px">
              <LabelInfo label="DATE OF RECRUITMENT">
                {formatDate(data.recruitment_date)}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="WHERE RECRUITMENT?">
                {data.recruitment_place}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <LabelInfo label="CIRCUMSTANCES OF RECRUITMENT?">
                {data.recruitment_circumstances}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">
                REASONS FOR JOINING THE ORGANIZATION
              </div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {reasonsForJoining.map((item) => (
                  <li
                    key={item.label}
                    className="mb-10px"
                    style={{ width: item.width }}
                  >
                    <input
                      checked={item.label === data.reasons_for_joining}
                      onChange={() => {}}
                      type="checkbox"
                    />{' '}
                    {item.label}
                  </li>
                ))}
              </ul>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">IDENTITIES OF THE RECRUITER/S</div>
              {getRecruiters(data.recruiter_identities)}
            </li>
            <li className="mb-10px">
              <div className="mb-10px">STATUS OF PARTY MEMBERSHIP</div>
              <ul
                style={{
                  listStyleType: 'none',
                  display: 'flex',
                  flexWrap: 'wrap',
                  padding: 0,
                  marginBottom: 10,
                }}
              >
                {statusOfPartyMembership.map((item) => (
                  <li key={item} className="mb-10px" style={{ width: '33%' }}>
                    <input
                      checked={item === data.membership_status}
                      type="checkbox"
                      onChange={() => {}}
                    />{' '}
                    {item}
                  </li>
                ))}
              </ul>
            </li>
            <li className="mb-10px">
              <LabelInfo label="CURRENT CATEGORY OF MEMBERSHIP IN THE ORGANIZATION">
                {data.membership_category}
              </LabelInfo>
            </li>
            <li className="mb-10px">
              <div className="mb-10px">
                POSITION/S HELD IN THE ORGANIZATION (FROM RECRUITMENT TO
                PRESENT)
              </div>
              <table className="table-bordered w-100">
                <tbody>
                  <tr>
                    <td width="">ORGANIZATION</td>
                    <td width="">POSITION</td>
                    <td width="">AREA OF OPERATIONS</td>
                    <td width="">INCLUSIVE DATES</td>
                  </tr>
                  {getPositions(data.positions_held)}
                </tbody>
              </table>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

React.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default FrHistory;
