import React from 'react';
import PropTypes from 'prop-types';

const LabelInfo = ({
  label,
  children,
  labelStyle,
  childrenStyle,
  wrapperWidth,
}) => (
  <div className="label-info" style={{ width: wrapperWidth }}>
    <div className="label" style={labelStyle}>
      {label}:
    </div>
    <div className="value" style={childrenStyle}>
      {children ?? ''}
    </div>
  </div>
);

LabelInfo.propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.any,
  labelStyle: PropTypes.instanceOf(React.CSSProperties),
  childrenWidth: PropTypes.string,
  childrenStyle: PropTypes.instanceOf(React.CSSProperties),
  wrapperWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default LabelInfo;
