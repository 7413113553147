export default (data) => {
  try {
    if (data) {
      const parseData = (args) => {
        return JSON.parse(
          `{"${decodeURI(args).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"')}"}`
        );
      };
      if (data.indexOf('?') === 0) {
        const newData = data.replace('?', '');
        return parseData(newData);
      }
      return parseData(data);
    }
    return {};
  } catch (err) {
    return {};
  }
};
