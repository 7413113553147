import _ from 'lodash';

const formatPayload = (data) => {
  let args = {};
  _.keys(data).forEach((item) => {
    args[`filters[${item}]`] = data[item];
  });
  return args;
};

export default formatPayload;
